<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="12">
            <h4 id="traffic" class="card-title mb-0">Sensores - Lista de Tanques y Bebederos</h4>
            <div class="small text-muted">Adminsitración de todos los tanques y bebederos con sus sensores</div>
          </b-col>    
        </b-row>
      </b-card>      

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col md="12">
            <div class="pull-right sensors-customer-view-icon">
              <b-icon icon="grid" 
                      class="mr-2" 
                      :class="{'selected-custom': view.grid}" 
                      @click="changeView('grid')"
                      v-b-tooltip.hover title="Modo Grilla">
              </b-icon>
              <b-icon icon="geo" 
                      :class="{'selected-custom': view.map}" 
                      @click="changeView('map')"
                      v-b-tooltip.hover title="Modo Mapa">
              </b-icon>               
              <b-icon icon="list" 
                      class="mr-2"
                      :class="{'selected-custom': view.list}" 
                      @click="changeView('list')"
                      v-b-tooltip.hover title="Modo Lista">
              </b-icon>             
            </div>
          </b-col>  
        </b-row> 
      </b-card>

      <b-row>
        <b-col md="12" v-if="view.list">
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-tabs card>
                  <b-tab title="Tanques" active>
                    <b-card-text>
                      <b-table class="mb-0"
                          responsive
                          head-variant="light"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          stacked="sm"
                          :items="tableTanks.items"
                          :fields="tableTanks.fields"
                          v-if="tableTanks.items.length">
                        
                        <template v-slot:cell(tank)="row">
                          <b-media tag="li">
                            <template v-slot:aside>
                              <span v-if="row.item.image" >
                                <b-img :src="row.item.image" thumbnail fluid width="100"/>
                              </span>
                              <span v-else>
                                <b-img :src="row.item.tank.image" v-if="row.item.tank.image" thumbnail fluid width="100"/>
                                <div class="list-sensores-thumbnail-custom" v-else>
                                  <b-icon icon="image" class="h1" v-b-tooltip.hover title="Imagen No Disponible" />
                                </div>    
                              </span>
                            </template>                                    
                            <h5 class="mt-0 mb-1">{{row.item.tank.code}} - {{row.item.tank.name}}</h5>      
                            <p class="mb-0">                  
                              <b>Diametro:</b> {{row.item.tank.diameter}} cm<br>
                              <b>Alto:</b> {{row.item.tank.height}} cm<br>                                    
                              <b>Capacidad:</b> {{row.item.tank.capacity}} lt<br> 
                              <b-badge variant="success" v-if="row.item.tank.own">Tanque Propio</b-badge>
                              <b-badge variant="danger" v-else>Tanque de Tercero</b-badge>                                         
                            </p>
                          </b-media>                                  
                        </template>                                

                        <template v-slot:cell(info)="row">                                  
                          <b-media tag="li" v-if="row.item">
                            <h5 class="mt-0 mb-1" v-if="row.item.alias">
                              {{row.item.alias}}
                            </h5>
                            <p class="mb-0">                    
                              <b>Serie: </b> {{row.item.serial_number}}<br>
                              <b>Vencimiento: </b> {{moment(row.item.expiration).format('DD/MM/YYYY')}}<br>                                      
                              <b>Contenido: </b> {{row.item.type}}<br>                                                                                                          
                            </p>
                          </b-media>                                  
                        </template> 
                        
                        <template v-slot:cell(sensor)="row">
                          <b-media tag="li" class="mb-0" v-if="row.item">                                    
                            <b>Nodo:</b> {{row.item.sensor_code}} |
                            <b>Sensor:</b> {{row.item.sensor_val}}<br>                                                                                          
                            
                            <div v-if="row.item.alert_min">
                              <b>Alerta:</b> {{row.item.alert_min}}%<br>
                            </div>
                            <div v-if="row.item.alert_min_last">
                              <b>Última Alerta:</b> {{row.item.alert_min_last}}%<br>
                            </div>
                            
                            <b-button variant="outline-success" size="sm" class="mt-2" @click="irViewData(row.item)">
                              <b-icon icon="graph-up" aria-hidden="true"></b-icon>  
                                Visualizar Datos
                              <b-icon icon="chevron-right" aria-hidden="true"></b-icon>                                                                                    
                            </b-button>                  
                          </b-media>                                                            
                        </template> 

                        <template v-slot:cell(f_action)="row">
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                            
                            <b-dropdown-item @click="irViewData(row.item)">
                              <b-icon icon="graph-up" aria-hidden="true" style="color:green"></b-icon>  
                                Visualizar Datos
                            </b-dropdown-item> 
                                                                
                            <b-dropdown-item @click="irNotifications(row.item)">
                              <b-icon icon="bell" aria-hidden="true" style="color:green"></b-icon>  
                                Notificaciones
                            </b-dropdown-item>

                            <b-dropdown-header>CRUD</b-dropdown-header>
                            
                            <b-dropdown-item @click="openEditTank(row.item)">
                              <b-icon icon="pencil" aria-hidden="true" style="color:blue"></b-icon>  
                                Editar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>

                      </b-table>
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Bebederos">
                    <b-card-text>
                      <b-table class="mb-0"
                          responsive
                          head-variant="light"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          stacked="sm"
                          :items="tableTrough.items"
                          :fields="tableTrough.fields"
                          v-if="tableTrough.items.length">
                        
                        <template v-slot:cell(alias)="row">    
                          <b-media tag="li">
                            <template v-slot:aside>                          
                              <span v-if="row.item.image" >
                                <b-img :src="row.item.image" thumbnail fluid width="100"/>
                              </span>                             
                            </template>                                                                                                                            
                            <h5 class="mt-0 mb-1">{{row.item.alias_trough}}</h5>
                          </b-media>
                        </template>                                
                                                      
                        <template v-slot:cell(sensor)="row">                          
                          <b>Nodo:</b> {{row.item.sensor_code}}
                        </template> 

                        <template v-slot:cell(status)="row">   
                          <div v-if="row.item.last_data_register">                               
                            <div v-if="row.item.last_data_register.entrada" class="list-sensors-customers-trough-open">
                              Abierto
                            </div>
                            <div v-else class="list-sensors-customers-trough-close">
                              Cerrado
                            </div>                                  
                          </div>
                          <div v-else>
                            <i>No Implementado</i>
                          </div>
                        </template>

                        <template v-slot:cell(f_action)="row">
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                                    
                            
                            <b-dropdown-item @click="irViewData(row.item)">
                              <b-icon icon="graph-up" aria-hidden="true" style="color:green"></b-icon>  
                                Visualizar Datos
                            </b-dropdown-item> 
                                                                
                            <b-dropdown-item @click="irNotifications(row.item)">
                              <b-icon icon="bell" aria-hidden="true" style="color:green"></b-icon>  
                                Notificaciones
                            </b-dropdown-item>
                                                        
                            <b-dropdown-header>CRUD</b-dropdown-header>
                            
                            <b-dropdown-item @click="openEditTank(row.item)">
                              <b-icon icon="pencil" aria-hidden="true" style="color:blue"></b-icon>  
                                Editar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>

                      </b-table>
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-card-text>
                  </b-tab>                  
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    
      <b-row>  
        <b-col md="12" v-if="view.grid">     
          <b-tabs card>
            <b-tab title="Tanques" active>
              <b-card-text>

                <b-row v-if="arr.tanks.length">
                  <b-col lg="4"   
                        md="6"                    
                        sm="6"  
                        xs="12"                
                        v-for="(value, index) in arr.tanks" 
                        v-bind:key="value.id">

                          <b-card
                            no-body 
                            class="overflow-hidden sensors-tank-customer-grid-card"                 
                          >
                            <b-row no-gutters>
                              <b-col md="6" class="d-none d-md-block">
                                <b-link href="#" @click="irViewData(value)">
                                  <span v-if="value.image">
                                    <b-card-img  :src="value.image" :alt="value.tank.code + '-' + value.tank.name" class="rounded-0 p-3"></b-card-img>
                                  </span>
                                  <span v-else>
                                    <b-card-img v-if="value.tank.image" :src="value.tank.image" :alt="value.tank.code + '-' + value.tank.name" class="rounded-0 p-3"></b-card-img>
                                    <div class="list-sensores-thumbnail-custom" v-else>
                                      <b-icon icon="image" class="h1" v-b-tooltip.hover title="Imagen No Disponible" />
                                    </div>                                                
                                  </span>
                                </b-link>
                              </b-col>
                              <b-col md="6" sm="12" xs="12" class="d-block">
                                <b-card-body>

                                  <div class="pull-right">
                                    <b-button size="sm" variant="outline-dark" @click="openEditTank(value)" title="Editar Nombre">
                                      <b-icon icon="pencil"></b-icon>
                                    </b-button>                        
                                  </div>                      
                                  <div v-if="value.alias" class="sensors-tank-customer-grid-card-title">
                                    <h5 class="card-title sensors-customer-title-main">{{value.alias}}</h5>
                                  </div>
                                  <div v-else class="sensors-tank-customer-grid-card-title">
                                    <i class="sensors-customer-title-main">
                                      <h5>[[Sin nombre]]</h5>                          
                                    </i>
                                  </div>
                                                        
                                  <b-card-text v-if="arr.summary[index]" >                        
                                    <h6 class="sensors-customer-title mt-3">Resumen</h6>
                                    <div v-if="arr.summary[index].litersStatus>=0">                          
                                      <span class="sensors-customer-data-main">
                                        <span v-if="!isCylinder(value.tank)">                                                              
                                          <i class="sensors-customer-summary-data-title">Litros:</i>&nbsp;
                                          <span v-if="arr.summary[index].litersStatus" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==2 }">                 
                                            {{arr.summary[index].litersStatus}} 
                                          </span>
                                          <span v-else>
                                            <b-badge variant="secondary">Sin Registro</b-badge>
                                          </span>
                                        </span>
                                        <span v-else>
                                          <i class="sensors-customer-summary-data-title">Litros:</i>&nbsp;
                                          <span v-if="arr.summary[index].litersStatusCylinder" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==2 }">                 
                                            {{arr.summary[index].litersStatusCylinder}} 
                                          </span>
                                          <span v-else>
                                            <b-badge variant="secondary">Sin Registro</b-badge>
                                          </span>                              
                                        </span>
                                      </span>
                                      <br>
                                      <span class="sensors-customer-data-main">
                                        <i class="sensors-customer-summary-data-title">Estado:</i>&nbsp;
                                        <span v-if="arr.summary[index].cantidadStatus" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==2 }">                 
                                          {{arr.summary[index].cantidadStatus}}%
                                        </span>
                                        <span v-else>
                                          <b-badge variant="secondary">Sin Registro</b-badge>
                                        </span>                          
                                      </span>       
                                    </div>
                                    <!--
                                    <div v-if="isCone(value.tank)">
                                      <span class="sensors-customer-data-main mt-1">                                                              
                                        <i class="sensors-customer-summary-data-title">Lts Cono:</i>&nbsp;
                                        <span v-if="arr.summary[index].litersStatusCone" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[index].cantidadStatus, value.alert_min, value.alert_min_last)==2 }">                 
                                          {{arr.summary[index].litersStatusCone}}                             
                                        </span>
                                        <span v-else>
                                          <b-badge variant="secondary">Sin Registro</b-badge>
                                        </span>
                                      </span>
                                    </div>
                                    -->
                                                        
                                    <h6 class="sensors-customer-title mt-3">Información</h6>
                                    <span class="sensors-customer-data-main">                                                              
                                      <i class="sensors-customer-summary-data-title">Capacidad:</i>&nbsp;
                                      <span>                 
                                        {{value.tank.capacity}} lts
                                      </span>
                                    </span><br>
                                    <span class="sensors-customer-data-main">                                                              
                                      <i class="sensors-customer-summary-data-title">Cargar:</i>&nbsp;
                                      <span>                 
                                        {{value.type}}
                                      </span>
                                    </span>                        
                                  </b-card-text>

                                  <hr>

                                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irViewData(value)">
                                    <b>
                                      Detalle
                                      <b-icon icon="chevron-double-right"></b-icon>
                                    </b>
                                  </b-button>
                                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irNotifications(value)">
                                    <b>
                                      Notificaciones
                                      <b-icon icon="graph-up"></b-icon>                          
                                    </b>
                                  </b-button>

                                </b-card-body>
                              </b-col>
                            </b-row>
                          </b-card>            
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col>
                    <b-alert variant="warning" show>No se encontraron registros</b-alert>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-tab>    
            <b-tab title="Bebederos">
              <b-card-text>

                <b-row v-if="arr.trough.length">
                  <b-col lg="3"   
                        md="6"                    
                        sm="6"  
                        xs="12"                
                        v-for="(value, index) in arr.trough" 
                        v-bind:key="value.id">

                          <b-card
                            no-body 
                            class="overflow-hidden sensors-tank-customer-grid-card"                 
                          >
                            <b-row no-gutters>
                              
                              <b-col md="12" sm="12" xs="12" class="d-block">
                                <b-card-body>

                                  <div class="pull-right">
                                    <b-button size="sm" variant="outline-dark" @click="openEditTank(value)" title="Editar Nombre">
                                      <b-icon icon="pencil"></b-icon>
                                    </b-button>                        
                                  </div>                      
                                  <div v-if="value.alias_trough" class="sensors-tank-customer-grid-card-title">
                                    <h5 class="card-title sensors-customer-title-main">{{value.alias_trough}}</h5>
                                  </div>
                                  <div v-else class="sensors-tank-customer-grid-card-title">
                                    <i class="sensors-customer-title-main">
                                      <h5>[[Sin nombre]]</h5>                          
                                    </i>
                                  </div>     

                                  <span v-if="value.image">
                                    <b-card-img :src="value.image" class="rounded-0 p-2"></b-card-img>
                                  </span>                                       

                                  <div v-if="value.last_data_register">                               
                                    <div v-if="value.last_data_register.entrada" class="list-sensors-customers-trough-open">
                                      Abierto
                                    </div>
                                    <div v-else class="list-sensors-customers-trough-close">
                                      Cerrado
                                    </div>                                  
                                  </div>
                                  <div v-else>
                                    <i>No Implementado</i>
                                  </div>                                                        

                                  <hr>

                                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irViewData(value)">
                                    <b>
                                      Detalle
                                      <b-icon icon="chevron-double-right"></b-icon>
                                    </b>
                                  </b-button>
                                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irNotifications(value)">
                                    <b>
                                      Notificaciones
                                      <b-icon icon="graph-up"></b-icon>                          
                                    </b>
                                  </b-button>

                                </b-card-body>
                              </b-col>
                            </b-row>
                          </b-card>            
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col>
                    <b-alert variant="warning" show>No se encontraron registros</b-alert>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" v-if="view.map">
          <div class="sensor-customer-list-custom-mapa">                
            <l-map          
              :options="maps.options"
              :zoom="maps.zoom"
              :center="maps.center"                    
            >
              <l-tile-layer :url="maps.url"></l-tile-layer>
              <l-marker v-for="(item, key) in maps.markerLatLng" v-bind:key="key" :lat-lng="item.geo">
                <l-popup class="sensors-tank-customer-map-popup" v-if="item.item.type_asignation=='tanque'">

                  <h4>{{item.name}}</h4>

                  <h6 class="sensors-customer-title">Resumen</h6>
                  <div v-if="arr.summary[item.key].litersStatus>=0"> 
                    <span class="sensors-customer-data-main">  
                      <span v-if="!isCylinder(item.item.tank)">                                                                     
                        <i class="sensors-customer-summary-data-title">Litros:</i>&nbsp;
                        <span v-if="arr.summary[item.key].litersStatus" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==2 }">                 
                          {{arr.summary[item.key].litersStatus}} 
                        </span>
                        <span v-else>
                          <b-badge variant="secondary">Sin Registro</b-badge>
                        </span>
                      </span>
                      <span v-else>                          
                        <i class="sensors-customer-summary-data-title">Litros:</i>&nbsp;
                        <span v-if="arr.summary[item.key].litersStatusCylinder" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==2 }">                 
                          {{arr.summary[item.key].litersStatusCylinder}} 
                        </span>
                        <span v-else>
                          <b-badge variant="secondary">Sin Registro</b-badge>
                        </span>                              
                      </span>                        
                    </span>


                    <div class="mt-1" />
                    <span class="sensors-customer-data-main">
                      <i class="sensors-customer-summary-data-title">Estado:</i>&nbsp;
                      <span v-if="arr.summary[item.key].cantidadStatus" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==2 }">                 
                        {{arr.summary[item.key].cantidadStatus}}%
                      </span>
                      <span v-else>
                        <b-badge variant="secondary">Sin Registro</b-badge>
                      </span>                          
                    </span>    
                  </div>
                  <!--
                  <div class="mt-1" />
                  <div v-if="isCone(item.item.tank)">
                    <span class="sensors-customer-data-main">                                                              
                      <i class="sensors-customer-summary-data-title">Lts Cono:</i>&nbsp;
                      <span v-if="arr.summary[item.key].litersStatusCone" :class="{ 'sensors-customer-summary-data-value-darkgreen': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==0, 'sensors-customer-summary-data-value-darkorange': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==1, 'sensors-customer-summary-data-value-darkred': alertMin(arr.summary[item.key].cantidadStatus, item.alert_min, item.alert_min_last)==2 }">                 
                        {{arr.summary[item.key].litersStatusCone}}                             
                      </span>
                      <span v-else>
                        <b-badge variant="secondary">Sin Registro</b-badge>
                      </span>
                    </span>
                  </div>
                  -->

                  <hr>                        

                  <h6 class="sensors-customer-title mt-3">Información</h6>
                  <span class="sensors-customer-data-main">                                                              
                    <i class="sensors-customer-summary-data-title">Capacidad:</i>&nbsp;
                    <span>                 
                      {{item.item.tank.capacity}} lts
                    </span>
                  </span>
                  <div class="mt-1" />
                  <span class="sensors-customer-data-main">                                                              
                    <i class="sensors-customer-summary-data-title">Cargar:</i>&nbsp;
                    <span>                 
                      {{item.item.type}}
                    </span>
                  </span>      

                  <hr> 
                                      
                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irViewData(item)">
                    <b>
                      Ver Detalle
                      <b-icon icon="chevron-double-right"></b-icon>
                    </b>
                  </b-button>  
                
                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irNotifications(item)">
                    <b>
                      Notificaciones
                      <b-icon icon="graph-up"></b-icon>                          
                    </b>
                  </b-button>                    
                  
                </l-popup>        
                <l-popup class="sensors-tank-customer-map-popup" v-if="item.item.type_asignation=='bebedero'">
                  <h4>{{item.name}}</h4>    
                  <div v-if="item.item.last_data_register">                               
                    <div v-if="item.item.last_data_register.entrada" class="list-sensors-customers-trough-open">
                      Abierto
                    </div>
                    <div v-else class="list-sensors-customers-trough-close">
                      Cerrado
                    </div>                                  
                  </div>
                  <div v-else>
                    <i>No Implementado</i>
                  </div>        

                  <hr> 
                                      
                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irViewData(item)">
                    <b>
                      Ver Detalle
                      <b-icon icon="chevron-double-right"></b-icon>
                    </b>
                  </b-button>  
                
                  <b-button size="sm" variant="outline-dark" class="w-100 sensors-tank-customer-grid-card-link mb-1" @click="irNotifications(item)">
                    <b>
                      Notificaciones
                      <b-icon icon="graph-up"></b-icon>                          
                    </b>
                  </b-button>                                                            
                </l-popup>                        
              </l-marker>                             
            </l-map>                            
          </div>
        </b-col>
      </b-row>
     
      <b-card header-tag="header" footer-tag="footer" class="mt-2">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>
          </b-col>
        </b-row>       
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->
      
      <!-- CRUD TANK -->      
      <b-modal v-model="modal.formTank.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formTank.title}}
        </div>

        <b-row v-if="crud.formTank.type_asignation=='tanque'">                 
          <b-col md="6">
            <b-form-group label="Alias" description="Ingrese un alias para el tanque">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.alias">
              </b-form-input>
            </b-form-group>
          </b-col>       

          <b-col md="6">          
            <b-form-group label="Imagen">
              <Imagen :images="crud.formTank.image" 
                      @load-image="crud.formTank.image = $event" 
                      typeImage="Imagen"
                      :compressImage="true"/>
            </b-form-group>            
          </b-col> 

          <b-col md="12">
            <b-card
              header="Alertas"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">              

              <b-card-text>
                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Alerta" description="Porcentaje mínimo para disparar alertas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      v-model="crud.formTank.alert_min">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Última Alerta" description="Último porcentaje mínimo para disparar alertas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      v-model="crud.formTank.alert_min_last">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                   
                </b-row>

                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Latitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lat">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Longitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lng">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                   
                </b-row>                
              </b-card-text>
            </b-card>                
          </b-col>
        </b-row>
        
        <b-row v-if="crud.formTank.type_asignation=='bebedero'">                 
          <b-col md="6">
            <b-form-group label="Alias" description="Ingrese un alias para el bebedero">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.alias_trough">
              </b-form-input>
            </b-form-group>
          </b-col>       
          
          <b-col md="6">          
            <b-form-group label="Imagen">
              <Imagen :images="crud.formTank.image" 
                      @load-image="crud.formTank.image = $event" 
                      typeImage="Imagen"
                      :compressImage="true"/>
            </b-form-group>            
          </b-col> 

          <b-col md="12">
            <b-card
              header="Alertas"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">              

              <b-card-text>

                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Latitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lat">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Longitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lng">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                   
                </b-row>                
              </b-card-text>
            </b-card>                
          </b-col>
        </b-row>
      
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTank.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTank()">Guardar</b-button>
        </div>
      </b-modal>  

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Imagen from '@/components/inc/image/image' 
  
  import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
  import { Icon } from 'leaflet'
  import 'leaflet/dist/leaflet.css';  
  import { ComponentContext } from '@fullcalendar/core'

  delete Icon.Default.prototype._getIconUrl

  Icon.Default.mergeOptions({    
    iconUrl: Session.getSession().settings.favicon,        
    shadowUrl: Session.getSession().settings.favicon,
    iconSize: [25, 25 * 1.15],
    iconAnchor: [25 / 2, 25 * 1.15],    
    shadowSize: [25, 25 * 1.15],
    shadowAnchor: [25 / 2, 25 * 1.15],        
  })

  export default {  
    components: {       
      LMap,
      LTileLayer,
      LMarker,  
      LPopup, 
      LIcon,
      Imagen,
    },    
    data: () => {
      return {
        primaryColor: '',
        customerID: 0,
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.CLIENTE,
          view_reference: 'listSensorsTank',
          elements: {}
        },            
        tableTanks : {
          items: [],
          fields: [            
            {key: 'tank', label: 'Información del Tanque', class:'align-middle'},
            {key: 'info', label:'Información Complementaria', class:'align-middle'},
            {key: 'sensor', label:'Información del Sensor', class:'align-middle'},            
            {key: 'f_action', label:'', class:'align-middle'},
          ],
        }, 
        tableTrough : {
          items: [],
          fields: [            
            {key: 'alias', label: 'Alias', class:'align-middle'},
            {key: 'sensor', label:'Sensor', class:'text-center align-middle'},            
            {key: 'status', label:'Estado', class:'text-center align-middle'},            
            {key: 'f_action', label:'', class:'align-middle'},
          ],
        },         
        crud: {
          formTank: {   
            tank: {
              id: 0,
              name: '',
              code: '',
            },                     
            id: 0,
            expiration: '',
            serial_number: '',
            type: '',
            customer_id: 0,
            tank_id: 0,
            sensor_code: 0,
            sensor_val: 0,
            alert_min: 0,
            alert_min_last: 0,
            alias: '',
            lat: '',
            lng: '',            
            batery_volt: 0,
            batery_alert: 0,            
            variation: 0,
            capacity: 0,
            distance_sensors: 0,        
            type_asignation: 'tanque',
            alias_trough: '',
            image: [],
          },        
        },          
        modal: {
          formTank: {
            active: false,
            title: ''
          },
        },
        view: {
          grid: true,
          list: false,
          map: false,
        },
        arr: {
          tanks: [],
          trough: [],
          all: [],
          summary: [],
        },
        maps: {
          urlMaps: '',
          url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
          options: {zoomControl: true, zoomSnap: true},          
          zoom: 13,
          center: [],
          markerLatLng: [],               
        },                                  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Config Customer */      
      this.customerID = Helper.getCustomerId()
      /* Fin Config Customer */
    },
    mounted() {      
      this.cargar()      
    },  
    methods: {
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerTanques(this.customerID)

        result.then((response) => {
          var data = response.data

          this.tableTanks.items = []          
          this.tableTrough.items = []
          this.arr.tanks = []
          this.arr.trough = []
          this.arr.all = []

          let array = data          
          array.forEach(element => {                                
            this.arr.all.push(element)

            if(element.type_asignation == 'tanque')  {
              this.tableTanks.items.push(element)
              this.arr.tanks.push(element)
            }

            if(element.type_asignation == 'bebedero')  {
              this.tableTrough.items.push(element)
              this.arr.trough.push(element)
            }
          });         

          var latTotal = 0
          var lngTotal = 0
          var cont = 0
          this.arr.all.forEach((element, index) => {   

            if(element.type_asignation == 'tanque') {
              this.loadLastReport(element, index)
           
              if(element.lat && element.lng) {          
                this.maps.urlMaps = 'https://www.openstreetmap.org/#map=19/' + element.lat + '/' + element.lng

                latTotal = latTotal + parseFloat(element.lat)
                lngTotal = lngTotal + parseFloat(element.lng)
                cont = cont + 1
              
                this.maps.markerLatLng.push({ 
                  key: index,             
                  id: element.id, 
                  name: element.alias, 
                  geo:[element.lat, element.lng],
                  alert_min: element.alert_min,
                  alert_min_last: element.alert_min_last,
                  item: element
                })
              }                                              
            }

            if(element.type_asignation == 'bebedero') {                         
              if(element.lat && element.lng) {          
                this.maps.urlMaps = 'https://www.openstreetmap.org/#map=19/' + element.lat + '/' + element.lng

                latTotal = latTotal + parseFloat(element.lat)
                lngTotal = lngTotal + parseFloat(element.lng)
                cont = cont + 1
              
                this.maps.markerLatLng.push({ 
                  key: index,             
                  id: element.id, 
                  name: element.alias_trough, 
                  geo:[element.lat, element.lng],
                  alert_min: element.alert_min,
                  alert_min_last: element.alert_min_last,
                  item: element
                })
              }                                              
            }
            
          });   
          this.maps.center = [latTotal / cont, lngTotal / cont]
          
          loader.hide()
        })
        .catch(error => {   
          loader.hide()   
          this.$awn.alert(Error.showError(error))
        });
      },
      openEditTank(item) {            
        //this.crud.formTank.tank.id = item.tank.id  
        //this.crud.formTank.tank.name = item.tank.name        

        this.crud.formTank.id = item.id        
        this.crud.formTank.serial_number = item.serial_number
        this.crud.formTank.expiration = item.expiration ? item.expiration : '1900-01-01'
        this.crud.formTank.type = item.type
        this.crud.formTank.customer_id = item.m_customers_id
        this.crud.formTank.tank_id = item.m_sensors_tanks_id
        this.crud.formTank.sensor_code = item.sensor_code
        this.crud.formTank.sensor_val = item.sensor_val ? item.sensor_val : 0 
        this.crud.formTank.alert_min = item.alert_min
        this.crud.formTank.alert_min_last = item.alert_min_last
        this.crud.formTank.alias = item.alias
        this.crud.formTank.lat = item.lat ? item.lat : '' 
        this.crud.formTank.lng = item.lng ? item.lng : ''
        this.crud.formTank.batery_volt = item.batery_volt
        this.crud.formTank.batery_alert = item.batery_alert
        this.crud.formTank.variation = item.variation      
        this.crud.formTank.capacity = item.capacity
        this.crud.formTank.distance_sensors = item.distance_sensors
        this.crud.formTank.type_asignation = item.type_asignation
        this.crud.formTank.alias_trough = item.alias_trough

        if(item.image) {
          this.crud.formTank.image = [{ path: item.image }]
        } else {
          this.crud.formTank.image = []
        }

        if(item.type_asignation == 'tanque') {
          this.modal.formTank.title = "Editar Tanque Asignado"
        } else {
          this.modal.formTank.title = "Editar Bebedero Asignado"
        }
        
        this.modal.formTank.active = true
      },
      saveTank() {
        let loader = this.$loading.show();        

        var image = new FormData();
        this.crud.formTank.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var result = serviceAPI.editarVinculacionTanque(JSON.stringify(this.crud.formTank), image);       

        result.then((response) => {
          this.modal.formTank.active = false
          loader.hide()
          this.cargar()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      changeView(valView) {
        if(valView == 'grid') {
          this.view.grid = true
          this.view.list = false
          this.view.map = false
        } 
        
        if(valView == 'list') {
          this.view.list = true
          this.view.grid = false          
          this.view.map = false
        }

        if(valView == 'map') {
          this.view.map = true
          this.view.list = false
          this.view.grid = false          
        }
      },            
      alertMin(cantStatus, alert_min, alert_min_last) {
        var status = 0
        if(cantStatus < alert_min) {
          status = 1;
        }
        if(cantStatus < alert_min_last) {
          status = 2;
        }        
        return status
      },
      loadLastReport(tankCustomer, index) {
        var data = {
          node: tankCustomer.sensor_code,
          sensor: tankCustomer.sensor_val
        }        
        var result = serviceAPI.obtenerLastReport(data)

        result.then((response) => {
          var data = response.data 
          
          this.arr.summary[index] = {
            cantidadStatus: this.getCantidadStatus(data.val, tankCustomer),
            litersStatus: this.getLiters(data.val, tankCustomer),
            litersCone: this.getLitersCone(0,tankCustomer),
            litersStatusCone: this.getLitersStatusCone(data.val,tankCustomer), 
            litersCylinder: this.getLitersCylinder(tankCustomer),
            litersStatusCylinder: this.getLitersStatusCylinder(data.val, tankCustomer),
          }

          this.$forceUpdate()
        }) 
      },
      
      getCantidadStatus(value, tankCustomer) {                
        if(value) {
          if(this.isCone(tankCustomer.tank)) {
            let heightConeTank = parseFloat(tankCustomer.tank.height) + parseFloat(tankCustomer.tank.cone_height)
            return (((heightConeTank - (value + parseFloat(tankCustomer.distance_sensors))) / heightConeTank) * 100).toFixed(2)
          } else {
            return (((tankCustomer.tank.height - (value + parseFloat(tankCustomer.distance_sensors))) / tankCustomer.tank.height) * 100).toFixed(2)
          }
        } else {
          return 0
        }        
      },
      getLiters(value, tankCustomer) {
        let liters = 0

        if(value) {
          if(tankCustomer.capacity>0) {
            liters = ((tankCustomer.capacity * this.getCantidadStatus(value, tankCustomer)) / 100)
          } else {
            liters = ((tankCustomer.tank.capacity * this.getCantidadStatus(value, tankCustomer)) / 100)
          }          
        } else {
          liters = 0
        }
        
        if(this.isCone) {
          liters = liters + this.getLitersStatusCone(value, tankCustomer)
        } 
        
        return liters.toFixed(2)
      },      

      getLitersCone (radioMajor, tankCustomer) {
        var result = 0
        if(this.isCone(tankCustomer.tank)) {
          var cone_height = tankCustomer.tank.cone_height           
          var cone_radio_minor = tankCustomer.tank.cone_radio_minor
          var cone_radio_major = 0
          if(radioMajor) {
            cone_radio_major = radioMajor
          }            
          else {
            cone_radio_major = tankCustomer.tank.cone_radio_major
          }   
          
          var calcRadioMajorCuadrado =  cone_radio_major * cone_radio_major
          var calcRadioMenorCuadrado =  cone_radio_minor * cone_radio_minor
          var calcMultiplicacionRadios = cone_radio_major * cone_radio_minor
          var calcSumaRadios = calcRadioMajorCuadrado + calcRadioMenorCuadrado + calcMultiplicacionRadios

          var calcPIxAltura = Math.PI * cone_height
          var calcFraccionUnoSobreTres = 1/3
          
          var calcFinal = ((calcFraccionUnoSobreTres * calcPIxAltura) * calcSumaRadios) / 1000

          result = calcFinal
        }
        return result
      },
      getLitersStatusCone (value, tankCustomer) {
        var result = 0
        if(this.isCone(tankCustomer.tank)) {
          var cone_height = tankCustomer.tank.cone_height           
          var cone_radio_minor = tankCustomer.tank.cone_radio_minor
          var cone_radio_major = tankCustomer.tank.cone_radio_major

          var cone_radio_major_new = 0

          var newHeightCone = parseFloat(tankCustomer.tank.height) - (value + parseFloat(tankCustomer.distance_sensors))

          if(newHeightCone<0) {
            newHeightCone = Math.abs(newHeightCone)
            var new_radio_major = (newHeightCone * cone_radio_major) / cone_height
            result = this.getLitersCone(cone_radio_major, tankCustomer) - this.getLitersCone(new_radio_major, tankCustomer) 
          } else {            
            result = this.getLitersCone(cone_radio_major, tankCustomer)
          }                   
        }
        return parseFloat(result)
      },
      isCone(tank) {          
        if(tank) {
          if( parseFloat(tank.cone_height)>0 &&
              parseFloat(tank.cone_radio_major)>0 &&
              parseFloat(tank.cone_radio_minor)>0 ) {
                return true              
          } else {
            return false
          }
        } else {
          return false
        }
      }, 

      getLitersCylinder(tankCustomer){
        var result = 0
        if(this.isCylinder) {
          var cylinder_longitude = parseFloat(tankCustomer.tank.cylinder_longitude)
          var cylinder_radio = parseFloat(tankCustomer.tank.cylinder_radio)

          var calcFinal = Math.PI * ((cylinder_radio * cylinder_radio) * cylinder_longitude)
          result = calcFinal / 1000      
        }
        return result
      },
      getLitersStatusCylinder(value, tankCustomer) {
        var result = 0
        if(this.isCylinder) {
          var cylinder_longitude = parseFloat(tankCustomer.tank.cylinder_longitude)
          var cylinder_radio = parseFloat(tankCustomer.tank.cylinder_radio)
          var cylinder_height = (parseFloat(tankCustomer.tank.cylinder_radio) * 2) - value          
  
          var calc1 = (Math.acos((cylinder_radio-cylinder_height)/cylinder_radio)) * (cylinder_radio*cylinder_radio)
          var calc2 = (cylinder_radio-cylinder_height) * cylinder_radio * Math.sin(Math.acos(((cylinder_radio - cylinder_height)/cylinder_radio)))
          var calc3 = ((calc1 - calc2) * cylinder_longitude) / 1000

          result = calc3                    
        }
        return parseFloat(result).toFixed(2) 
      },
      isCylinder(tank) {      
        if(tank) {
          if(parseFloat(tank.cylinder_longitude)>0 &&
              parseFloat(tank.cylinder_radio)>0 ) {
                return true              
          } else {
            return false
          }
        } else {
          return false
        }
      },  

      irViewData(item) {        
        this.$router.push({ name: 'SensorsCustomerDetailSensorsTank', params: { customerTankID: item.id } })
      },      
      irNotifications(item) {
        this.$router.push({ name: 'SensorsCustomerNotificationsSensorsTank', params: { customerTankID: item.id } })                  
      }      
    }  
  }
</script>
<style>
  .list-sensores-thumbnail-custom{
    text-align: center;
    margin-top: 25px;
  }
  .list-sensores-thumbnail-custom svg {
    padding: 0.25rem;
    background-color:
    #e4e5e6;
    border: 1px solid
    #c8ced3;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;    
  }
  .sensors-customer-view-icon {
    font-weight: bold;
    font-size: 20px;
    cursor: pointer    
  }
  .sensor-customer-list-custom-mapa {
    padding: 0px;
    height: 500px !important;
  }  
  .sensors-customer-title {
    padding: 5px;
    background: #dedede;
    font-weight: 700;
  }
  .sensors-customer-data-main {
    font-size: 15px;
    margin-left: 10px;
  }    
  .sensors-customer-title-main {
    font-weight: bold;
  }
  .sensors-customer-summary-data-title {    
    font-weight: bold;
  }  
  .sensors-customer-summary-data-value-darkred {    
    font-weight: bold;
    color: white;
    background: darkred;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }  
  .sensors-customer-summary-data-value-darkorange {    
    font-weight: bold;
    color: white;
    background: darkorange;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;    
  }      
  .sensors-customer-summary-data-value-darkgreen {    
    font-weight: bold;
    color: white;
    background: darkgreen;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;        
  } 
  .sensors-tank-customer-grid-card-link{
    font-size: 13px;
  }    
  .sensors-tank-customer-grid-card-title{
    height: 50px;
  }
  .sensors-tank-customer-grid-card{
    max-width: 540px; 
    max-height: 450px;
  }

  .sensors-tank-customer-map-popup {    
    max-height: 400px;
  }

  .list-sensors-customers-trough-open {
    font-size: 18px;
    text-transform: uppercase;
    color: darkgreen;
    font-weight: bold;
  } 
  .list-sensors-customers-trough-close {
    font-size: 18px;
    text-transform: uppercase;
    color: darkred;
    font-weight: bold;
  }  
</style>